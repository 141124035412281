var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    [
      _vm._l(_vm.teams, function(team) {
        return _c("li", { key: team.id }, [
          _c(
            "button",
            {
              staticClass:
                "block w-full focus:outline-none focus:bg-gray-100 hover:bg-gray-100",
              on: {
                click: function($event) {
                  return _vm.teamSelected(team)
                }
              }
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "ml-4 uppercase flex justify-between py-3 pr-4 border-b border-blue-500"
                },
                [
                  _c("p", {
                    staticClass: "w-3/4 text-left truncate mr-2",
                    domProps: { textContent: _vm._s(team.name) }
                  }),
                  _c("div", { staticClass: "flex" }, [
                    _c("p", {
                      domProps: { textContent: _vm._s(team.usersCount) }
                    }),
                    _c("p", { staticClass: "ml-2 flex items-center" }, [
                      !_vm.selectedTeam || _vm.selectedTeam.id !== team.id
                        ? _c("img", {
                            staticClass: "h-3 w-3",
                            attrs: {
                              src: require("@/assets/images/ic_chevron_right_blue.svg")
                            }
                          })
                        : _c("button", { staticClass: "btn-loading h-3 w-3" })
                    ])
                  ])
                ]
              )
            ]
          )
        ])
      }),
      _vm.teams.length === 0
        ? _c("li", {
            staticClass: "ml-4 pr-4 py-3 text-md text-gray-900",
            domProps: {
              textContent: _vm._s(
                _vm.$t("incident_assign_teams_list.label_empty_list")
              )
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }