var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ml-4" }, [
    _c(
      "div",
      {
        staticClass:
          "flex flex-row justify-between items-center\n      w-full pr-4 py-3 border-b border-blue-500"
      },
      [
        _c("div", { staticClass: "flex items-center w-2/3" }, [
          _c("div", { staticClass: "flex-none w-7 mr-2" }, [
            _vm.user.avatar !== null
              ? _c("img", {
                  staticClass: "w-7 h-7 rounded-full",
                  attrs: {
                    src: _vm.user.avatar.medium,
                    alt: _vm.$t("general.label_profile_picture")
                  }
                })
              : _c("img", {
                  staticClass: "w-7 h-7",
                  attrs: {
                    src: require("@/assets/images/avatar_placeholder.svg"),
                    alt: _vm.$t("general.label_profile_picture")
                  }
                })
          ]),
          _c("div", { staticClass: "flex flex-col leading-tight w-4/5" }, [
            _c("p", {
              staticClass: "font-semibold truncate",
              class: { "text-gray-500": !_vm.user.onDuty },
              domProps: { textContent: _vm._s(_vm.user.shortName) }
            }),
            _c("p", {
              staticClass:
                "text-xs uppercase text-gray-500 truncate max-w-full",
              domProps: {
                textContent: _vm._s(_vm.distance + " • " + _vm.assignments)
              }
            })
          ])
        ]),
        _c("button", {
          staticClass:
            "btn rounded-full ml-3 px-3 py-1 border-0 uppercase\n      focus:outline-none text-xs tracking-wider truncate",
          class: {
            "btn-loading": _vm.loading,
            "btn-blue": !_vm.isAssigned,
            "bg-gray-200 text-blue-500":
              _vm.isAssigned || !_vm.incident.canAssign,
            "cursor-not-allowed": !_vm.incident.canAssign
          },
          attrs: {
            disabled: !_vm.incident.canAssign || _vm.loading || _vm.isAssigned
          },
          domProps: {
            textContent: _vm._s(
              !_vm.isAssigned
                ? _vm.$t("general.button_assign")
                : _vm.$t("general.button_assigned")
            )
          },
          on: { click: _vm.assign }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }