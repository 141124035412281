export default {
  data() {
    return {
      atBottom: false,
      ref: null,
      callback: null,
    };
  },
  watch: {
    atBottom() {
      if (!this.atBottom) {
        return;
      }

      this.callback();
    },
  },
  methods: {
    attachInfiniteScroll(ref, callback) {
      this.ref = ref;
      this.callback = callback;

      if (!this.ref || !this.ref.$el) {
        return;
      }

      this.ref.$el.addEventListener('scroll', this.updateAtBottom);
    },
    updateAtBottom() {
      const scrollable = this.ref.$el;

      const scrollY = scrollable.scrollTop;
      const visible = scrollable.clientHeight;
      const divHeight = scrollable.scrollHeight;
      const bottomOfDiv = visible + scrollY >= divHeight - 25;

      this.atBottom = (bottomOfDiv || divHeight < visible);
    },
    detachInfiniteScroll() {
      if (!this.ref || !this.ref.$el) {
        return;
      }

      this.ref.$el.removeEventListener('scroll', this.updateAtBottom);

      this.ref = null;
      this.callback = null;
    },
  },
};
