<template>
  <div class="ml-4">
    <div
      class="flex flex-row justify-between items-center
        w-full pr-4 py-3 border-b border-blue-500">
      <div class="flex items-center w-2/3">
        <div class="flex-none w-7 mr-2">
          <img
            v-if="user.avatar !== null"
            :src="user.avatar.medium"
            :alt="$t('general.label_profile_picture')"
            class="w-7 h-7 rounded-full"
          >
          <img
            v-else
            src="@/assets/images/avatar_placeholder.svg"
            :alt="$t('general.label_profile_picture')"
            class="w-7 h-7"
          >
        </div>
        <div class="flex flex-col leading-tight w-4/5">
          <p
            v-text="user.shortName"
            class="font-semibold truncate"
            :class="{'text-gray-500': !user.onDuty}">
          </p>
          <p
            v-text="`${distance} • ${assignments}`"
            class="text-xs uppercase text-gray-500 truncate max-w-full">
          </p>
        </div>
      </div>
      <button
        v-text="!isAssigned ? $t('general.button_assign') : $t('general.button_assigned')"
        class="btn rounded-full ml-3 px-3 py-1 border-0 uppercase
        focus:outline-none text-xs tracking-wider truncate"
        :class="{
          'btn-loading': loading,
          'btn-blue': !isAssigned,
          'bg-gray-200 text-blue-500': isAssigned || !incident.canAssign,
          'cursor-not-allowed': !incident.canAssign,
        }"
        :disabled="!incident.canAssign || loading || isAssigned"
        @click="assign"
      ></button>
    </div>
  </div>
</template>

<script>
  import User from '@/models/User';
  import Incident from '@/models/Incident';

  export default {
    name: 'IncidentAssignUserListItem',
    props: {
      user: { type: User, default: null },
      incident: { type: Incident, default: null },
    },
    data() {
      return {
        loading: false,
        isAssignedNow: false,
      };
    },
    computed: {
      isAssigned() {
        if (!this.incident) {
          return false;
        }

        return this.incident.assignedUsers.some(
          assignedUser => assignedUser.id === this.user.id,
        );
      },
      distance() {
        if (!this.user.onDuty) {
          return this.$t('incident_assign_user_list_item.label_off_duty');
        }

        if (this.user.lat === null || this.user.long === null || this.user.distance === null) {
          return this.$t('incident_assign_user_list_item.label_location_unknown');
        }

        if (this.user.distance > 1000) {
          return this.$t('general.label_KM', {
            amount: (this.user.distance / 1000).toFixed(1),
          });
        }

        return this.$t('general.label_M', {
          amount: this.user.distance.toFixed(1),
        });
      },
      assignments() {
        return this.$t('incident_assign_user_list_item.label_assignment_count', {
          amount: this.user.assignedIncidentsCount + (this.isAssignedNow ? 1 : 0),
        });
      },
    },
    methods: {
      assign() {
        if (this.isAssigned) {
          return;
        }

        this.loading = true;

        this.$store
          .dispatch('incident/assignUser', {
            user: this.user,
            incident: this.incident,
          })
          .then(() => {
            this.loading = false;
            this.isAssignedNow = true;
          });
      },
    },
  };
</script>
