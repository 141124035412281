<template>
  <ul>
    <li
      v-for="team in teams"
      :key="team.id">
      <button
        @click="teamSelected(team)"
        class="block w-full focus:outline-none focus:bg-gray-100 hover:bg-gray-100"
      >
        <div class="ml-4 uppercase flex justify-between py-3 pr-4 border-b border-blue-500">
          <p
            v-text="team.name"
            class="w-3/4 text-left truncate mr-2"></p>
          <div class="flex">
            <p v-text="team.usersCount"></p>
            <p class="ml-2 flex items-center">
              <img
                v-if="!selectedTeam || selectedTeam.id !== team.id"
                src="@/assets/images/ic_chevron_right_blue.svg"
                class="h-3 w-3"
              >
              <button
                v-else
                class="btn-loading h-3 w-3"></button>
            </p>
          </div>
        </div>
      </button>
    </li>
    <li
      v-if="teams.length === 0"
      v-text="$t('incident_assign_teams_list.label_empty_list')"
      class="ml-4 pr-4 py-3 text-md text-gray-900">
    </li>
  </ul>
</template>

<script>
  import Incident from '@/models/Incident';

  export default {
    name: 'IncidentAssignTeamsList',
    props: {
      teams: { type: Array, default: () => [] },
      incident: { type: Incident, default: new Incident() },
    },
    data() {
      return {
        selectedTeam: null,
      };
    },
    methods: {
      teamSelected(team) {
        if (this.selectedTeam) {
          return;
        }

        this.selectedTeam = team;

        this.$store
          .dispatch('team/loadByIdAndCoordinates', {
            id: team.id,
            lat: this.incident.lat,
            long: this.incident.long,
          })
          .then(() => {
            this.selectedTeam = null;
          });
      },
    },
  };
</script>
