<template>
  <div>
    <ul class="h-full">
      <li
        v-for="user in users"
        :key="user.id">
        <incident-assign-user-list-item
          :user="user"
          :incident="incident"/>
      </li>
      <li
        v-if="users.length === 0"
        v-text="$t('incident_assign_users_list.label_empty_list')"
        class="ml-4 pr-4 py-3 text-md text-gray-900">
      </li>
    </ul>
  </div>
</template>

<script>
  import Incident from '@/models/Incident';
  import IncidentAssignUserListItem from '@/components/listItems/IncidentAssignUserListItem.vue';

  export default {
    name: 'IncidentAssignUsersList',
    components: {
      IncidentAssignUserListItem,
    },
    props: {
      incident: { type: Incident, default: new Incident() },
      users: { type: Array, default: () => [] },
    },
  };
</script>
