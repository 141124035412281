var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex flex-col" }, [
    _c(
      "nav",
      {
        staticClass:
          "flex flex-row px-3 mt-3 pb-2 border-b border-blue-500 items-end"
      },
      [
        _c("v-back", {
          attrs: {
            close: true,
            fallback: _vm.$router.resolve({
              name: "incident-detail",
              params: { id: _vm.id }
            })
          }
        }),
        _c("p", {
          staticClass:
            "w-full flex justify-center -ml-7 font-semibold pointer-events-none",
          domProps: {
            textContent: _vm._s(
              _vm.$t("the_incident_assign.label_assign_users")
            )
          }
        })
      ],
      1
    ),
    _vm.incident && !_vm.incident.canAssign
      ? _c("p", {
          staticClass: "form-error",
          domProps: {
            textContent: _vm._s(
              _vm.$t("the_incident_assign.label_can_not_assign")
            )
          }
        })
      : _vm._e(),
    _c(
      "div",
      { staticClass: "flex-grow flex flex-col" },
      [
        _c(
          "div",
          {
            staticClass:
              "ml-4 py-3 pr-4 border-b border-blue-500 flex items-center"
          },
          [
            _vm.selectedTeam !== null || _vm.searchValue
              ? _c(
                  "button",
                  {
                    staticClass: "mr-2 focus:outline-none",
                    on: { click: _vm.back }
                  },
                  [
                    _c("img", {
                      staticClass: "w-4 h-4",
                      attrs: {
                        src: require("@/assets/images/chevron_left.svg"),
                        alt: _vm.$t("general.button_back")
                      }
                    })
                  ]
                )
              : _vm._e(),
            _c(
              "form",
              {
                staticClass: "w-full",
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.search($event)
                  }
                }
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchValue,
                      expression: "searchValue"
                    }
                  ],
                  staticClass: "form-input-blocked",
                  attrs: {
                    type: "text",
                    placeholder: _vm.$t(
                      "the_incident_assign.placeholder_search_users"
                    ),
                    disabled: _vm.incident === null
                  },
                  domProps: { value: _vm.searchValue },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.searchValue = $event.target.value
                    }
                  }
                })
              ]
            )
          ]
        ),
        !_vm.loading && !_vm.error
          ? _c(
              "v-scrollable",
              { ref: "scrollable" },
              [
                _vm.teams !== null && _vm.users === null && !_vm.searchValue
                  ? _c("incident-assign-teams-list", {
                      attrs: { incident: _vm.incident, teams: _vm.teams }
                    })
                  : _vm._e(),
                _vm.users !== null
                  ? _c("incident-assign-users-list", {
                      attrs: { incident: _vm.incident, users: _vm.users }
                    })
                  : _vm._e()
              ],
              1
            )
          : _vm._e(),
        _vm.loading ? _c("v-loading") : _vm._e(),
        _vm.error !== null
          ? _c("v-error", {
              model: {
                value: _vm.error,
                callback: function($$v) {
                  _vm.error = $$v
                },
                expression: "error"
              }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }